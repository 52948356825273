// UserForm.js
import React, { useState } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiFieldPassword,
    EuiSelect,
    EuiFieldNumber,
    EuiTextArea,
    EuiButton,
    EuiText,
} from '@elastic/eui';
import useFetch from "react-fetch-hook"
import { PostOptions } from '../utils/RequestUtils';

const CourseAddForm = ({ onSuccess }) => {
    const [form, setForm] = useState({
        course_name: '',
        course_number: '',
        course_description: '',
        image_url: 'https://picsum.photos/400/200',
    });

    
    const [isLoading, setisLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage(null);
        setHasError(false);
        setisLoading(true);
        fetch("/api/course/add", PostOptions(form))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setErrorMessage(text.message);
                        setHasError(true);
                        throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setisLoading(false);
                onSuccess();
            })
            .catch(error => {

                console.error('There was an error!', error);
                setisLoading(false);
            });
    };

    return (
        <EuiForm component="form" onSubmit={handleSubmit} isInvalid={hasError} error={errorMessage} >
            <EuiFormRow label="CourseName">
                <EuiFieldText
                    name="course_name"
                    value={form.course_name}
                    onChange={handleChange}
                />
            </EuiFormRow>

           <EuiFormRow label="Course Number">
                <EuiFieldText
                    name="course_number"
                    value={form.course_number}
                    onChange={handleChange}
                />
            </EuiFormRow>

            <EuiFormRow label="Course Description">
                <EuiTextArea fullWidth value={form.course_description} onChange={handleChange} name='course_description'/>
            </EuiFormRow>

            <EuiFormRow label="Image URL">
                <EuiFieldText
                    name="image_url"
                    value={form.image_url}
                    onChange={handleChange}
                />
            </EuiFormRow>

            <EuiButton type="submit" fill isLoading={isLoading}>
                Create
            </EuiButton>
        </EuiForm>
    );
};

export default CourseAddForm;
