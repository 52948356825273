// UserForm.js
import React, { useState } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiFieldPassword,
    EuiSelect,
    EuiFieldNumber,
    EuiButton,
    EuiText,
} from '@elastic/eui';
import useFetch from "react-fetch-hook"
import { PostOptions } from '../utils/RequestUtils';

const CreateSesssionForm = ({ course, config_name, onSuccess }) => {
    const [form, setForm] = useState({
        session_name: '',
        course_name: course.course_name,
        config_name: config_name,
        course_number: course.course_number,
    });

    const [isLoading, setisLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });       
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        setErrorMessage(null);
        setHasError(false);
        setisLoading(true);
        fetch("/api/session/create", PostOptions(form))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setErrorMessage(text.message);
                        setHasError(true);
                        throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setisLoading(false);
                onSuccess();
            })
            .catch(error => {

                console.error('There was an error!', error);
                setisLoading(false);
            });
    };

    return (
        <EuiForm component="form" onSubmit={handleSubmit} isInvalid={hasError} error={errorMessage} >
            <EuiFormRow label="SessionName" isInvalid={form.session_name === ''}
                >
                <EuiFieldText
                    name="session_name"
                    value={form.session_name}
                    onChange={handleChange}
                    isInvalid={form.session_name === ''}
                />
            </EuiFormRow>
            <EuiButton type="submit" fill isLoading={isLoading}>
                Create
            </EuiButton>
        </EuiForm>
    );
};

export default CreateSesssionForm;
