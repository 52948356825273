import React, { useState, useMemo, useContext } from 'react';
import {
    EuiPageTemplate,
    EuiButton,
    EuiTabs,
    EuiTab,
    EuiTitle,
    EuiLink,
    EuiImage,
    EuiPanel,
} from '@elastic/eui';
import illustration from '../images/illustration.svg';
import LoginForm from '../forms/LoginForm';
import RegisterForm from '../forms/RegisterForm';
import { Link } from 'react-router-dom';
import { UserContext } from '../Context';


const tabs = [
    {
        id: "login--id",
        name: "Login",
        content: (<LoginForm />)
    },
    {
        id: "register--id",
        name: "Register",
        content: (<RegisterForm />)
    }
];

const HomePage = () => {
    const [selectedTabId, setSelectedTabId] = useState('login--id');
    const selectedTabContent = useMemo(() => {
        return tabs.find((obj) => obj.id === selectedTabId)?.content;
    }, [selectedTabId]);
    const {user} = useContext(UserContext);

    const onSelectedTabChanged = (id) => {
        setSelectedTabId(id);
    };
    
    return (
        <EuiPageTemplate minHeight="0">
            <EuiPageTemplate.EmptyPrompt
                title={<h2>Welcome to MerryQuery!</h2>}
                icon={<EuiImage size="fullWidth" src={illustration} alt="" />}
                color="plain"
                layout="horizontal"
                body={
                    <>
                        <p>
                            "MerryQuery" is an AI-powered educational assistant that utilizes retrieval-augmented geeration (RAG)
                            to provide students with tailored responses based on course materials.
                        </p>
                        {!user && 
                            <p>
                                To access MerryQuery, you need to <Link to="/auth">login or register first</Link>.
                            </p>
                        }
                        {user &&
                            <p>
                                Hi {user.username}! You are already logged in. You can now access MerryQuery.
                            </p>
                        
                        }
                    </>
                }
                actions={
                    <EuiPanel hasBorder={false} hasShadow={false}>
                        {/* <p>Place holder! Place holder! Place holder!</p> */}
                        {/* <Link to="/auth">To auth page</Link> */}
                    </EuiPanel>
                }
                footer={
                    <>
                        <EuiTitle size="xxs">
                            <span>Want to learn more?</span>
                        </EuiTitle>{' '}
                        <EuiLink href="https://github.com/benneigh/MerryQuery" target="_blank">
                            Read the docs
                        </EuiLink>
                    </>
                }
            />
        </EuiPageTemplate>
    );
};
export default HomePage;