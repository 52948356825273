// UserForm.js
import React, { useState } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiFieldPassword,
    EuiSelect,
    EuiFieldNumber,
    EuiButton,
    EuiText,
} from '@elastic/eui';
import { PostOptions } from '../utils/RequestUtils';

const PasswordEditForm = () => {
    const [form, setForm] = useState({
        password: '',
        confirmPassword: '',
    });

    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isLoading, setisLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });

        if (name === 'password' || name === 'confirmPassword') {
            setPasswordsMatch(form.password === value || form.confirmPassword === value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!passwordsMatch) {
            alert('Passwords do not match');
            return;
        }
        fetch("/api/password/edit", PostOptions({ new_password: form.password }))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setErrorMessage(text.message);
                        setHasError(true);
                        throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setisLoading(false);
                setHasError(false);
                setErrorMessage(null);
                
            })
            .catch(error => {
                console.error('There was an error!', error);
                setisLoading(false);
            });
    };
        

    return (
        <EuiForm component="form" onSubmit={handleSubmit} isInvalid={hasError} error={errorMessage} >
            <EuiFormRow label="Password" isInvalid={!passwordsMatch} error={passwordsMatch ? null : 'Passwords do not match'}>
                <EuiFieldPassword
                    name="password"
                    value={form.password}
                    onChange={handleChange}
                    isInvalid={!passwordsMatch}
                />
            </EuiFormRow>

            <EuiFormRow label="Confirm Password" isInvalid={!passwordsMatch} error={passwordsMatch ? null : 'Passwords do not match'}>
                <EuiFieldPassword
                    name="confirmPassword"
                    value={form.confirmPassword}
                    onChange={handleChange}
                    isInvalid={!passwordsMatch}
                />
            </EuiFormRow>

            <EuiButton type="submit" fill fullWidth isLoading={isLoading}>
                Update
            </EuiButton>
        </EuiForm>
    );
};

export default PasswordEditForm;
