// CourseList.js
import React, { useState, useContext, useEffect } from 'react';
import {
    EuiCard,
    EuiContextMenu,
    EuiPopover,
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
    EuiButton,
    EuiFieldSearch,
    EuiEmptyPrompt,
    EuiSkeletonText,
    EuiText,
    EuiLoadingElastic,
    EuiPanel,
    EuiFlexGrid,
    useIsWithinBreakpoints,
    EuiButtonEmpty,
    EuiLoadingLogo,
    EuiPage,
} from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Context';
import FileManagePage from './FileManagePage';
import Blocked from "../components/Blocked";
import JoinPage from "./JoinPage";
import RosterPage from "./RosterPage";
import CourseAddPage from "./CourseAddPage";
import { PostOptions } from '../utils/RequestUtils';

const CourseListPage = () => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [popoverCourse, setPopoverCourse] = useState(null);
    const [isFileManageModalVisible, setIsFileManageModalVisible] = useState(false);
    const [isJoinModalVisible, setIsJoinModalVisible] = useState(false);
    const [isRosterModalVisible, setIsRosterModalVisible] = useState(false);
    const [isCourseAddModalVisible, setIsCourseAddModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const isMobile = useIsWithinBreakpoints(['xs', 's']);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetch("/api/course/get", PostOptions({}))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setIsLoading(false);
                setCourses(data.courses);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setIsLoading(false);
            });
    }, [isCourseAddModalVisible, isJoinModalVisible, isRosterModalVisible]);



    const handleClick = (course) => {
        console.log('Clicked on:', course);
    };

    const togglePopover = (course) => {
        console.log('Toggled popover:', course);
        setPopoverCourse(course);
        setIsPopoverOpen((prevIsPopoverOpen) => !prevIsPopoverOpen);
    };

    const closePopover = () => {
        setIsPopoverOpen(false);
        setPopoverCourse(null);
    };

    const renderContextMenu = () => {
        const items = [
            {
                name: 'Add/Remove Materials',
                icon: 'documents',
                onClick: () => setIsFileManageModalVisible(true),
            },
            {
                name: 'Controls and Features',
                icon: 'gear',
                disabled: true,
                onClick: () => console.log('Controls and Features clicked'),
            },
            {
                name: 'Student Log Data',
                icon: 'editorAlignLeft',
                // disabled: true,navigate("/sessions/" + course.course_number + "/" + course.course_name)
                onClick: () => {
                    // console.log(popoverCourse);
                    navigate('/statistic/' + popoverCourse.course_number + '/' + popoverCourse.course_name);
                },
            },
            {
                name: 'Roster',
                icon: 'users',
                // disabled: true,
                onClick: () => setIsRosterModalVisible(true),
            },
            {
                name: 'Settings',
                icon: 'controlsHorizontal',
                disabled: true,
                onClick: () => console.log('Settings clicked'),
            },
        ];

        return <EuiContextMenu initialPanelId={0} panels={[{ id: 0, title: 'Options', items }]} />;
    };

    const handleSearchChange = (e) => {
        // Handle search logic here
        setSearchQuery(e.target.value);
        // console.log('Search query:', e.target.value);
    };
    if (!user) {
        return <Blocked name="Course List"/>;
    }
    // console.log('User:', user);
    
    if (isLoading) {
        return (
            <EuiPanel>
                <EuiSkeletonText lines={3} size="m" contentAriaLabel="loading">
                    <EuiText size="m"><p>loading</p></EuiText>
                </EuiSkeletonText>
            </EuiPanel>
        );
    }

    return (
        <EuiPage restrictWidth={"80%"}>
            <EuiPanel>
                <EuiFlexGroup direction='column' alignItems='left'>
                    <EuiFlexItem>
                        <EuiFlexGroup>
                            <EuiFlexItem grow={false}>
                                <EuiFieldSearch
                                    placeholder="Search Courses"
                                    fullWidth
                                    onChange={handleSearchChange}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButton iconType="kqlFunction" iconSize='l' onClick={() => setIsJoinModalVisible(true)}>Join</EuiButton>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiFlexItem grow={false}>
                                    <EuiButton iconType="plus" iconSize='l' onClick={() => setIsCourseAddModalVisible(true)}>Add Course</EuiButton>
                                </EuiFlexItem>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>
                {(courses.length > 0) &&
                            <EuiFlexGrid columns={4}>
                                {courses
                                    .filter((course) => (course.course_number + " " + course.course_name).toLowerCase().includes(searchQuery.toLowerCase()))
                                    .map((course) => (
                                        <EuiFlexItem key={course.id} grow={1}>
                                            <EuiCard
                                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                textAlign="left"
                                                image={course.image_url}
                                                title={course.course_number + " " + course.course_name}
                                                description={course.course_description}
                                                onClick={() => handleClick(course)}
                                                footer={
                                                    <EuiFlexGroup justifyContent="spaceBetween">
                                                        <EuiFlexItem grow={false}>
                                                            {user.role === 'teacher' && <EuiPopover
                                                                button={
                                                                    <EuiButtonIcon
                                                                        iconType="gear"
                                                                        iconSize='m'
                                                                        aria-label="More actions"
                                                                        onClick={() => togglePopover(course)}
                                                                    />
                                                                }
                                                                isOpen={isPopoverOpen && popoverCourse === course}
                                                                closePopover={closePopover}
                                                                anchorPosition="upLeft"
                                                            >
                                                                {renderContextMenu()}
                                                            </EuiPopover>}
                                                        </EuiFlexItem>
                                                        <EuiFlexItem grow={false}>
                                                            <EuiButtonEmpty onClick={() => navigate("/sessions/" + course.course_number + "/" + course.course_name)}>
                                                                Chat
                                                            </EuiButtonEmpty>
                                                        </EuiFlexItem>
                                                    </EuiFlexGroup>
                                                }
                                            />
                                        </EuiFlexItem>
                                    ))}
                            </EuiFlexGrid>
                }
                {(courses.length === 0) &&
                    <EuiEmptyPrompt
                            iconType="securityAnalyticsApp"
                        title={<EuiText><h2>No courses found</h2></EuiText>}
                        body={
                        user.role === "teacher" ? <EuiText><p>There are no courses available. Please join a course or add a new course.</p></EuiText> 
                        : <EuiText><p>There are no courses available. Please join a course.</p></EuiText>
                        
                    }
                    />
                }
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer size="l" />
            {popoverCourse && <><FileManagePage course={popoverCourse} isModalVisible={isFileManageModalVisible} setIsModalVisible={setIsFileManageModalVisible} />
                
                <RosterPage isModalVisible={isRosterModalVisible} setIsModalVisible={setIsRosterModalVisible} course={popoverCourse} />
                
            </>
            }
            <JoinPage isModalVisible={isJoinModalVisible} setIsModalVisible={setIsJoinModalVisible} />
            <CourseAddPage isModalVisible={isCourseAddModalVisible} setIsModalVisible={setIsCourseAddModalVisible} />
        </EuiPage>
    );
};

export default CourseListPage;
