// UserForm.js
import React, { useState, useEffect } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiFieldPassword,
    EuiSelect,
    EuiFieldNumber,
    EuiButton,
    EuiText,
    EuiInlineEditTitle,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSwitch
} from '@elastic/eui';
import useFetch from "react-fetch-hook"
import { PostOptions } from '../utils/RequestUtils';

const EditAgentForm = ({ config, course, onNameChange }) => {
    console.log(config, course);
    const [form, setForm] = useState({
        config_name: config.config_name,
        course_name: course.course_name,
        course_number: course.course_number,
        mode: config.mode,
    });
    const [preName, setPreName] = useState(config.config_name);
    useEffect(() => {
        setForm({
            config_name: config.config_name,
            course_name: course.course_name,
            course_number: course.course_number,
            mode: config.mode,
        });
        console.log(config.mode);
        setPreName(config.config_name);
    }, [config]);
    const [isLoading, setisLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    
    

    const changeName = () => {
        setisLoading(true);
        setErrorMessage(null);
        fetch("/api/agent/name/edit", PostOptions({ config_name: preName, course_name: course.course_name, new_name: form.config_name }))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setErrorMessage(text.message);
                        throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setisLoading(false); 
                onNameChange(preName, form.config_name);
                setPreName(form.config_name);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setisLoading(false);
            });
    };

    const changeMode = (value) => {
        setisLoading(true);
        setErrorMessage(null);
        fetch("/api/agent/mode/edit", PostOptions({ config_name: form.config_name, course_name: course.course_name, mode: value }))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setErrorMessage(text.message);
                        throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setisLoading(false);
                setForm({ ...form, mode: value });
            })
            .catch(error => {
                console.error('There was an error!', error);
                setisLoading(false);
            });
    };

    return (
        <EuiFlexGroup direction='column' gutterSize='xs'>
            <EuiFlexItem>
                <EuiInlineEditTitle
                    inputAriaLabel="Edit title inline"
                    value={form.config_name}
                    onChange={(e) => setForm({ ...form, config_name: e.target.value })}
                    onSave={changeName}
                    onCancel={() => setForm({ ...form, config_name: preName })}
                    heading="h3"
                    size="s"
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiSwitch
                    disabled={isLoading}
                    compressed
                    label={form.mode === 'review' ? 'Review' : 'Practice'}
                    checked={form.mode === 'review'}
                    onChange={(e) => changeMode(e.target.checked ? 'review' : 'practice')}
                />
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiText color="danger">{errorMessage}</EuiText>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default EditAgentForm;
