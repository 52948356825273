// UserForm.js
import React, { useContext, useState } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiFieldPassword,
    EuiSelect,
    EuiFieldNumber,
    EuiButton,
    EuiText,
} from '@elastic/eui';
import { UserContext } from '../Context';
import { PostOptions } from '../utils/RequestUtils';

const AgeEditForm = () => {
    const {user, setUser} = useContext(UserContext);
    const [form, setForm] = useState({
        age: user.age,
    });
    const [isLoading, setisLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const age = parseInt(form.age);
        if (isNaN(age) || age < 0) {
            setErrorMessage('Age must be a positive number');
            setHasError(true);
            return;
        }
        if (age < 12 || age > 120) {
            setErrorMessage('Please enter a valid age');
            setHasError(true);
            return;
        }
        fetch("/api/age/edit", PostOptions({ age: parseInt(form.age) }))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setErrorMessage(text.message);
                        setHasError(true);
                        throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setisLoading(false);
                setHasError(false);
                setErrorMessage(null);
                setUser({
                    ...user,
                    age: parseInt(form.age),
                });
            })
            .catch(error => {
                console.error('There was an error!', error);
                setisLoading(false);
            });
        
        console.log('Form data:', form);
    };

    return (
        <EuiForm component="form" onSubmit={handleSubmit} isInvalid={hasError} error={errorMessage}>
            <EuiFormRow label="Age">
                <EuiFieldNumber
                    name="age"
                    value={form.age}
                    onChange={handleChange}
                />
            </EuiFormRow>

            <EuiButton type="submit" fill fullWidth isLoading={isLoading}>
                Update
            </EuiButton>
        </EuiForm>
    );
};

export default AgeEditForm;
