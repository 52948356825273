// UserForm.js
import React, { useState, useContext } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiFieldPassword,
    EuiSelect,
    EuiFieldNumber,
    EuiButton,
    EuiText,
} from '@elastic/eui';
import { UserContext } from '../Context';
import { PostOptions } from '../utils/RequestUtils';
import { useNavigate } from 'react-router-dom';

const UserForm = () => {
    const [form, setForm] = useState({
        username: '',
        full_name: '',
        password: '',
        confirmPassword: '',
        role: '',
        age: '',
    });

    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [passwordsError, setPasswordsError] = useState(null);
    const [fullnameError, setFullnameError] = useState(null);
    const [usernameError, setUsernameError] = useState(null);
    const [roleError, setRoleError] = useState(null);
    const [ageError, setAgeError] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    if (user) {
        navigate('/');
    }

    // const {isLoading, data, error} = useFetch(":5000/test", requestOptions);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });

        if (name === 'password' || name === 'confirmPassword') {
            if (form.password === value || form.confirmPassword === value) {
                setPasswordsError(null);
            } else {
                setPasswordsError('Passwords do not match');
            }
        }
        if (name === "password") {
            if (value.length < 8) {
                setPasswordsError('Password must be at least 8 characters');
            } else {
                setPasswordsError(null);
            }
        }
        if (name === 'full_name') {
            if (value.length < 3) {
                setFullnameError('Full name must be at least 3 characters');
            } else {
                setFullnameError(null);
            }
        }
        if (name === 'username') {
            if (value.length < 3) {
                setUsernameError('Username must be at least 3 characters');
            } else {
                setUsernameError(null);
            }
        }
        if (name === 'role') {
            if (value === '') {
                setRoleError('Role must be selected');
            } else {
                setRoleError(null);
            }
        }
        if (name === 'age') {
            try {
                let age = parseInt(value);
                if (age < 12) {
                    setAgeError('Age must be at least 12');
                } else {
                    setAgeError(null);
                }
            } catch (error) {
                setAgeError('Age must be a number');
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!passwordsMatch) {
            alert('Passwords do not match');
            return;
        }
        console.log('Form data:', form);
        let data = {...form};
        try {
            data.age = parseInt(data.age);
        } catch (error) {
            return;
        }
        setErrorMessage(null);
        setHasError(false);
        setisLoading(true);
        fetch("/api/register", PostOptions(data))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        setErrorMessage(text.message);
                        setHasError(true);
                        throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setisLoading(false);
                setUser(data);
                navigate('/');
            })
            .catch(error => {

                console.error('There was an error!', error);
                setisLoading(false);
            });
    };

    return (
        <EuiForm component="form" onSubmit={handleSubmit} isInvalid={hasError} error={errorMessage} >
            <EuiFormRow label="Username" isInvalid={usernameError !== null}
                error={usernameError}>
                <EuiFieldText
                    name="username"
                    value={form.username}
                    onChange={handleChange}
                    isInvalid={usernameError !== null}
                />
            </EuiFormRow>

            <EuiFormRow label="Full Name" isInvalid={fullnameError !== null}
                error={fullnameError}>
                <EuiFieldText
                    name="full_name"
                    value={form.full_name}
                    onChange={handleChange}
                    isInvalid={fullnameError !== null}
                />
            </EuiFormRow>

            <EuiFormRow label="Password" isInvalid={passwordsError !== null} error={passwordsError}>
                <EuiFieldPassword
                    name="password"
                    value={form.password}
                    onChange={handleChange}
                    isInvalid={!passwordsMatch}
                />
            </EuiFormRow>

            <EuiFormRow label="Confirm Password" isInvalid={passwordsError !== null} error={passwordsError}>
                <EuiFieldPassword
                    name="confirmPassword"
                    value={form.confirmPassword}
                    onChange={handleChange}
                    isInvalid={!passwordsMatch}
                />
            </EuiFormRow>

            <EuiFormRow label="Role" isInvalid={roleError !== null}
                error={roleError}>
                <EuiSelect
                    name="role"
                    options={[
                        { value: '', text: 'Select a Role' },
                        { value: 'student', text: 'Student' },
                        { value: 'teacher', text: 'Teacher' },
                    ]}
                    value={form.role}
                    onChange={handleChange}
                    isInvalid={roleError !== null}
                />
            </EuiFormRow>

            <EuiFormRow label="Age" isInvalid={ageError !== null}
                error={ageError}>
                <EuiFieldNumber
                    name="age"
                    value={form.age}
                    onChange={handleChange}
                    isInvalid={ageError !== null}
                />
            </EuiFormRow>

            <EuiButton type="submit" fill isLoading={isLoading}>
                Register
            </EuiButton>
        </EuiForm>
    );
};

export default UserForm;
