import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import {
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiResizableContainer,
    EuiFieldSearch,
    EuiListGroup,
    EuiListGroupItem,
    EuiButton,
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiPopover,
    EuiTextArea,
    EuiFilePicker,
    EuiText,
    EuiSwitch,
    EuiContextMenuPanel,
    EuiPanel,
    EuiTitle,
    EuiSelectable,
    EuiContextMenuItem,
    EuiInlineEditTitle
} from '@elastic/eui';
import CreateAgentPage from '../pages/CreateAgentPage';
import { PostOptions } from '../utils/RequestUtils';

const AgentList = forwardRef(({ course, showCreate, onSelect, buttonText, showAll }, ref) => {

    const [isLoading, setIsLoading] = useState(true);
    const [createAgentModalVisible, setCreateAgentModalVisible] = useState(false);
    const [options, setOptions] = useState([]);
    const [isPopoverOpen, setPopover] = useState(false);
    const [agentName, setAgentName] = useState(null);
    const [agentConfig, setAgentConfig] = useState(null);

    useEffect(() => {
        const savedConfigName = localStorage.getItem('selectedAgentConfigName');
        fetch("/api/agent/get/all", PostOptions({ course_name: course.course_name }))
            .then(response => {
                return response.json().then(text => {
                    if (!response.ok) {
                        throw new Error(text.message);
                    }
                    return text;
                });
            })
            .then(data => {
                console.log(data);
                setIsLoading(false);
                let opt = data.data.map((agent) => ({
                    label: agent.config_name,
                    checked: agent.config_name === savedConfigName,
                    ...agent
                }));
                // run handleAgentChange to set the agent name and config
                handleAgentChange(opt);
                if (showAll) {
                    opt.unshift({
                        label: "All Agents",
                        checked: savedConfigName === "All Agents",
                        config_name: "All Agents",
                        agent_id: -1
                    });
                    if (savedConfigName === "All Agents") {
                        setAgentName("All Agents");
                        setAgentConfig({
                            label: "All Agents",
                            checked: true,
                            config_name: "All Agents",
                            agent_id: -1
                        });
                    }
                }
                console.log(opt);
                setOptions(opt);
            })
            .catch(error => {
                console.error('There was an error!', error);
                setIsLoading(false);
            });
    }, [createAgentModalVisible]);

    const handleAgentChange = (newOptions) => {
        setOptions(newOptions); 
    
        let checkedOptions = newOptions.filter((option) => option.checked);
        if (checkedOptions.length > 1) {
            checkedOptions = checkedOptions.slice(1);
        }
    
        const selectedOption = checkedOptions[0];
        setAgentName(selectedOption ? selectedOption.label : null);
        setAgentConfig(selectedOption ? selectedOption : null);
        setPopover(false);
        onSelect(selectedOption ? selectedOption : null);
    
        if (selectedOption) {
            localStorage.setItem('selectedAgentConfigName', selectedOption.config_name);
        }
    };

    const onNameChange = (oldName, newName) => {
        console.log("updateName", oldName, newName);
        setAgentName(newName);
        setOptions(options.map((option) => {
            if (option.label === oldName) {
                return {
                    ...option,
                    label: newName,
                };
            }
            return option;
        }));
    };

    useImperativeHandle(ref, () => ({
        onNameChange: onNameChange
    }));

    const onButtonClick = () => {
        setPopover(!isPopoverOpen);
    };

    const closePopover = () => {
        setPopover(false);
    };

    const button = (
        <EuiButton
            fullWidth
            size="s"
            iconType="arrowDown"
            iconSide="right"
            onClick={onButtonClick}
        >
            {agentName || buttonText}
        </EuiButton>
    );

    return (
        <>
            <EuiPopover
                button={button}
                color='text'
                isOpen={isPopoverOpen}
                closePopover={closePopover}
                panelPaddingSize="none"
                anchorPosition="downLeft"
                style={{ width: '100%' }}
            >
                <EuiContextMenuPanel >
                    {showCreate && <EuiFlexGroup alignItems="center" justifyContent='center'>
                        <EuiFlexItem>
                            <EuiContextMenuItem
                                key="item-1"
                                icon="indexOpen"
                                size="s"
                                onClick={() => {
                                    setCreateAgentModalVisible(true);
                                    setPopover(false);
                                }}
                            >
                                Create a New Agent
                            </EuiContextMenuItem>
                        </EuiFlexItem>
                    </EuiFlexGroup>}
                    <EuiPanel color="transparent" paddingSize="s" style={{ width: '100%' }}>
                        <EuiTitle size="xxxs">
                            <h3>Agents</h3>
                        </EuiTitle>
                        <EuiSpacer size="s" />
                        <EuiSelectable
                            aria-label="Find an Agent"
                            searchable
                            searchProps={{
                                compressed: true,
                                placeholder: "Find an Agent",
                            }}
                            options={options}
                            onChange={(newOptions) => handleAgentChange(newOptions)}
                        >
                            {(list, search) => (
                                <>
                                    {search}
                                    {list}
                                </>
                            )}
                        </EuiSelectable>
                    </EuiPanel>
                </EuiContextMenuPanel>
            </EuiPopover>
            {createAgentModalVisible && <CreateAgentPage isVisible={createAgentModalVisible} setIsVisible={setCreateAgentModalVisible} course={course} />}
        </>
    );
});
export default AgentList;