import React, { useState, useRef } from 'react';
import {
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiButton,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiSpacer,
} from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_light.css';
import { PostOptions } from '../utils/RequestUtils';

const JoinPage = ({ isModalVisible, setIsModalVisible }) => {
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [error, setError] = useState('');
    const inputRefs = useRef([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (index, value) => {
        if (value.length <= 1 && /^[0-9]*$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
            if (value && index < 5) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleSubmit = () => {
        const codeString = code.join('');
        const regex = /^\d{3}-\d{3}$/;
        const formattedCode = `${code.slice(0, 3).join('')}-${code.slice(3, 6).join('')}`;
        if (regex.test(formattedCode)) {
            setIsLoading(true);
            console.log('Verification code submitted:', formattedCode);
            fetch("/api/course/join", PostOptions({
                invitation_code: codeString,
            }))
                .then(response => {
                    return response.json().then(text => {
                        console.log(response)
                        if (!response.ok) {
                            setError(text.message);
                            setIsLoading(false);
                            setCode(['', '', '', '', '', '']);
                            throw new Error(text.message);
                        }
                        return text;
                    });
                })
                .then(data => {
                    console.log(data);
                    setIsLoading(false);
                    setError(null);
                    setIsModalVisible(false);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                    setIsLoading(false);
                    setCode(['', '', '', '', '', '']);
                    // setError(error);
                });
        } else {
            setError('Please enter a valid verification code (xxx-xxx)');
        }
    };
    if (!isModalVisible) {
        return null;
    }


    return (
        <EuiModal onClose={() => setIsModalVisible(false)}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>Enter Code to Join</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <EuiText>
                <p>Please enter the 6-digit verification code sent to to join the course.</p>
                </EuiText>
                <EuiSpacer />
                <EuiFlexGroup gutterSize="s" justifyContent='center'>
                    {code.slice(0, 3).map((char, index) => (
                        <EuiFlexItem key={index} grow={false}>
                            <EuiFieldText
                                maxLength={1}
                                inputRef={(el) => (inputRefs.current[index] = el)}
                                value={char}
                                onChange={(e) => handleChange(index, e.target.value)}
                                aria-label={`Verification character ${index + 1}`}
                                style={{ width: '3em', textAlign: 'center' }}
                            />
                        </EuiFlexItem>
                    ))}
                    <EuiFlexItem grow={false} style={{ alignSelf: 'center' }}>
                        <EuiText>-</EuiText>
                    </EuiFlexItem>
                    {code.slice(3, 6).map((char, index) => (
                        <EuiFlexItem key={index + 3} grow={false}>
                            <EuiFieldText
                                maxLength={1}
                                inputRef={(el) => (inputRefs.current[index + 3] = el)}
                                value={char}
                                onChange={(e) => handleChange(index + 3, e.target.value)}
                                aria-label={`Verification character ${index + 4}`}
                                style={{ width: '3em', textAlign: 'center' }}
                            />
                        </EuiFlexItem>
                    ))}
                </EuiFlexGroup>
                {error && (
                    <EuiText color="danger">
                        <p>{error}</p>
                    </EuiText>
                )}
            </EuiModalBody>

            <EuiModalFooter>
                <EuiButton onClick={handleSubmit} fill isDisabled={code.some((char) => char === '')}>
                    Submit
                </EuiButton>
            </EuiModalFooter>
        </EuiModal>
    );
};

export default JoinPage;
