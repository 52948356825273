// ChatHistorySideNav.js
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { css } from '@emotion/react';
import {
    EuiPanel,
    EuiListGroup,
    EuiListGroupItem,
    EuiFlexGroup,
    EuiFlexItem,
    EuiAvatar,
    EuiHorizontalRule,
} from '@elastic/eui';
import { PostOptions } from '../utils/RequestUtils';

const SessionList = ({ course, agent, fresh, selectedSession, setSelectedSession, setChatHistory }) => {
    // const [selectedSession, setSelectedSession] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {
        setisLoading(true);
        let config_name = agent? agent.config_name : "";
        if (config_name === "All Agents") {
            config_name = "";
        }
        fetch('/api/session/get/all', PostOptions({ course_name: course.course_name, 
            config_name: config_name
        })
        ).then(response => {
            return response.json().then(text => {
                if (!response.ok) {
                    
                    throw new Error(text.message);
                }
                return text;
            });
        })
            .then(data => {
                console.log(data.data);
                setisLoading(false);
                const currentTime = new Date();
                let sessionGroups = []; // today, yesterday, last week, older
                let today = [];
                let yesterday = [];
                let lastWeek = [];
                let older = [];
                data.data.forEach(session => {
                    const sessionTime = new Date(session.date_and_time);
                    const diff = currentTime - sessionTime;
                    const days = diff / (1000 * 60 * 60 * 24);
                    // console.log(currentTime, sessionTime, diff, days);
                    if (days < 1) {
                        today.push(session);
                    } else if (days < 2) {
                        yesterday.push(session);
                    } else if (days < 7) {
                        lastWeek.push(session);
                    } else {
                        older.push(session);
                    }
                });
                if (today.length > 0) {
                    sessionGroups.push({ name: 'Today', items: today });
                }
                if (yesterday.length > 0) {
                    sessionGroups.push({ name: 'Yesterday', items: yesterday });
                }
                if (lastWeek.length > 0) {
                    sessionGroups.push({ name: 'Last Week', items: lastWeek });
                }
                if (older.length > 0) {
                    sessionGroups.push({ name: 'Older', items: older });
                }
                setSessions(sessionGroups);
            })
            .catch(error => {

                console.error('There was an error!', error);
                setisLoading(false);
            });
    }, [agent, fresh[0], fresh[1]]);

    

    return (
        <EuiPanel paddingSize="none" style={{ height: '70vh', overflow: 'auto', maxWidth: "16vw"}} color="subdued" grow>
            {sessions.map((group, index) => (
                <EuiPanel key={index} hasShadow={false} hasBorder={false}>
                    <h3 >{group.name}</h3>
                    <EuiHorizontalRule margin='xs'/>
                    <EuiListGroup flush>
                        {group.items.map((item, index) => (
                            <EuiFlexGroup key={index}>
                                <EuiFlexItem grow={1}>
                                    <EuiAvatar name={item.config_name} size="s" />
                                </EuiFlexItem>
                                <EuiFlexItem grow={10}>
                                    <EuiListGroupItem
                                        // key={index}
                                        isActive={selectedSession && selectedSession.session_name === item.session_name}
                                        label={item.session_name}
                                        onClick={() => {
                                            setSelectedSession(item);
                                            setChatHistory(item.chat_history);
                                        }}
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        ))}
                    </EuiListGroup>
                </EuiPanel>
            ))}
        </EuiPanel>
    );
};

export default SessionList;
